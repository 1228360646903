import React, { useMemo, useEffect } from 'react';
import { Button, Select, Typography, Spinner } from '../../../Atoms';
import { FormInput as Input } from '../..';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { selectType, dataForm, version } from './utils';
import { AddEnvironmentModalProps } from './interface';
import { getSpinnerSize, getSpinnerStokeColor } from '../../../Atoms/Button/utils';

export const AddEnvironmentModal = ({ save }: AddEnvironmentModalProps) => {
  const [isTrue, setIsTrue] = useState<boolean>(false);

  const [selectData, setSelectData] = useState({
    type: selectType[0],
    version: version[0],
  });
  const { handleSubmit, control, register, watch, setValue } = useForm();
  const onSubmit = (data: any) => {
    setIsTrue(true);
    const type = selectData?.type.value;
    const version = selectData?.version.value;
    const obj = { type, version };
    data = {
      ...data,
      android_file: data['android_file'][0],
      banner: data['banner'][0],
      ios_file: data['ios_file'][0],
      model_file: data['model_file'][0],
      standalone_file: data['standalone_file'][0],
      thumbnail: data['thumbnail'][0],
    };
    const objData = { ...data, ...obj };
    save?.(dataForm(objData));
  };
  const labelTextClassName = [' mb-1 !text-caption !leading-caption !tracking-caption !font-normal   !text-[12px'].join(
    ' '
  );

  const { user_limit } = watch();

  useMemo(() => {
    const numericValue = parseInt(user_limit);

    if (!isNaN(numericValue) && numericValue >= 0) {
      setValue('user_limit', numericValue);
    } else {
      setValue('user_limit', '');
    }
  }, [user_limit]);

  const spinnerClasses = [getSpinnerStokeColor('neon'), getSpinnerSize('large')].join(' ');

  return (
    <form
      className='md:!w-[650px] max-h-[500px] overflow-auto overflow-y-auto overflow-x-hidden px-2'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='mb-6 px-4 '>
        <Input
          required
          className='w-full dark:text-neutral-400
          
          text-caption leading-caption tracking-caption font-normal mb-1 font-semibold  dark:text-neutral-600 text-[17px]   !text-caption !leading-caption !tracking-caption !font-normal  !font-normal !text-lg
          '
          control={control}
          label='Environment Name'
          placeholder='name'
          labelClassName='!text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
          name='name'
          type='text'
          maxLength={50}
        />
      </div>
      <div className='-mx-1  flex flex-wrap '>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2'>
          <Typography size='caption' className={labelTextClassName}>
            {'Android File'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            // required
            className='w-full  dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('android_file')}
          />
        </div>
        <div className='w-full mb-4 px-4 sm:w-1/2 md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'Banner'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            // required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('banner')}
          />
        </div>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'Standalone File'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            // required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('standalone_file')}
          />
        </div>
        <div className='w-full mb-4 px-4 sm:w-1/2 md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'Ios File'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            // required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('ios_file')}
          />
        </div>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'Model File'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            // required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('model_file')}
          />
        </div>

        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'thumbnail'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('thumbnail')}
          />
        </div>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2 mt-5'>
          <Input
            required
            className='w-full dark:text-neutral-400
            text-caption leading-caption tracking-caption font-normal mb-1 font-semibold  dark:text-neutral-600 text-[17px]   !text-caption !leading-caption !tracking-caption !font-normal   !text-base
            '
            control={control}
            label='User Limit'
            placeholder='user limit'
            labelClassName='!text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
            name='user_limit'
            type='number'
            min={0}
            value={user_limit}
          />
        </div>

        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2 mt-5'>
          <Input
            required
            className='w-full dark:text-neutral-400'
            control={control}
            label='Description'
            labelClassName='!text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
            placeholder='Description'
            maxLength={1000}
            name='description'
            type='text'
          />
        </div>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2 mt-3'>
          <Input
            required
            className='w-full dark:text-neutral-400'
            control={control}
            label='JPDescription'
            labelClassName='!text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
            placeholder='JP Description'
            name='jpDescription'
            type='text'
            maxLength={40}
          />
        </div>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2 mt-2'>
          <Input
            required
            className='w-full dark:text-neutral-400'
            control={control}
            label='Creator'
            placeholder='Creator'
            labelClassName='!text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
            name='creator'
            maxLength={50}
            type='text'
          />
        </div>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2 mt-3'>
          <Input
            required
            className='w-full dark:text-neutral-400'
            control={control}
            label='JP Creator'
            labelClassName='!text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
            placeholder='JP Creator'
            name='jpCreator'
            type='text'
            maxLength={20}
          />
        </div>
        <div className='w-full mb-4 px-4 sm:w-1/2 md:w-1/2 mt-2'>
          <Select
            label='Select Type'
            options={selectType}
            slectedClassName='!py-[4px]'
            labelTextClassName=' !text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
            value={selectData.type}
            setSelectedCollectionAsset={(data) => setSelectData({ ...selectData, type: data })}
          />
        </div>
        <div className='w-full px-4 sm:w-1/2 md:w-1/2'>
          <Select
            label='Select Version'
            labelTextClassName=' !text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
            options={version}
            slectedClassName='!py-[4px]'
            value={selectData.version}
            setSelectedCollectionAsset={(data) => setSelectData({ ...selectData, version: data })}
          />
        </div>
      </div>

      {isTrue ? (
        <div className='mb-6 w-full flex justify-end px-4 py-4'>
          <Spinner className={spinnerClasses} />{' '}
        </div>
      ) : (
        <div className='mb-6 w-full flex justify-end px-4 py-4'>
          <Button color='primary' size='small' variant='solid' type='submit' className='w-[200px] !px-10'>
            Save change
          </Button>
        </div>
      )}
    </form>
  );
};
